/* eslint react/prop-types: 0 */
import * as React from 'react';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getExteriorColors, getInteriorColors } from '../api/lookup';
import { IDynamicConditionAnswer, IDynamicConditionQuestion } from '../types/IConditionQuestion';
import { IColor, IVehicleInfo } from '../types/IVehicleInfo';
import { IVehicleStyle, IVehicleStyleDetail } from '../types/IVehicleStyle';
import { UseState } from '../types/UseStateType';
import { testNames, useFeatures } from './features';

const VehicleInfoContext = createContext<{
    exteriorColors: IColor[];
    featureInfo: IFeatureInfo;
    featureLookupInfo: IVehicleStyleDetail[][];
    interiorColors: IColor[];
    resetVehicleInfo: () => void;
    setFeatureInfo: UseState<IFeatureInfo>;
    setFeatureLookupInfo: UseState<IVehicleStyleDetail[][]>;
    setVehicleConditionInfo: UseState<IConditionInfo>;
    setVehicleInfo: UseState<IVehicleInfo>;
    questionColors: boolean;
    vehicleConditionInfo: IConditionInfo;
    vehicleInfo: IVehicleInfo;
}>(null);

interface IVehicleInfoProviderProps {
    children: ReactNode;
    InitialVehicleInfo: IVehicleInfo;
}

export const vehicleInfoInitialState: IVehicleInfo = {
    isComplete: false,
    vin: '',
    plate: null,
    state: null,
    mileage: null,
    zipcode: '',
    profile: {
        year: null,
        yearCode: null,
        make: null,
        makeCode: null,
        model: null,
        modelCode: null,
        trim: null,
        trimCode: null,
        bodyCode: null,
    },
};

export type IFeatureInfo = {
    isComplete: boolean;
    style: IVehicleStyle;
    drive: string;
    transmission: string;
    standardOptions: string[];
    availableOptions: string[];
};

const featureInfoInitialState: IFeatureInfo = {
    isComplete: false,
    style: null,
    drive: null,
    transmission: null,
    standardOptions: [],
    availableOptions: [],
};

export type IConditionInfo = {
    conditionAnswers: IDynamicConditionAnswer[];
    conditionQuestions: IDynamicConditionQuestion[];
    isComplete: boolean;
    mileage: string;
    errorCount: number;
    exteriorColor?: string;
    interiorColor?: string;
};

const conditionInfoInitialState: IConditionInfo = {
    conditionAnswers: [],
    conditionQuestions: [],
    isComplete: false,
    mileage: '',
    errorCount: 0,
};

const initalizeConditionAnswers = (
    conditionQuestions: IDynamicConditionQuestion[],
    existingAnswers: IDynamicConditionAnswer[]
): IDynamicConditionAnswer[] => {
    const answers: IDynamicConditionAnswer[] = [];
    conditionQuestions.forEach((question: IDynamicConditionQuestion) => {
        const answer = {
            id: question.id,
            answers: [],
        } as IDynamicConditionAnswer;

        const prevAnswer = existingAnswers.find(x => x.id === answer.id);
        answers.push(prevAnswer ? prevAnswer : answer);

        question.answers.forEach(answer => {
            if (answer.detailQuestions) {
                answers.push(...initalizeConditionAnswers(answer.detailQuestions, existingAnswers));
            }
        });
    });
    return answers;
};

const VehicleInfoProvider: React.FC<IVehicleInfoProviderProps> = ({ children, InitialVehicleInfo }) => {
    // TODO - Remove this when colors before a feature.
    const { isFeatureEnabled } = useFeatures();

    const [vehicleInfo, setVehicleInfo] = useState(Object.assign({}, vehicleInfoInitialState, InitialVehicleInfo));
    const [vehicleConditionInfo, setVehicleConditionInfo] = useState<IConditionInfo>(conditionInfoInitialState);
    const [featureInfo, setFeatureInfo] = useState<IFeatureInfo>(featureInfoInitialState);
    const [featureLookupInfo, setFeatureLookupInfo] = useState<IVehicleStyleDetail[][]>([]);
    const [conditionAnswersInitialized, setConditionAnswersInitialized] = useState(false);
    const [exteriorColors, setExteriorColors] = useState<IColor[]>([]);
    const [interiorColors, setInteriorColors] = useState<IColor[]>([]);
    const [colorsRequested, setColorsRequested] = useState<boolean>(false);

    useEffect(() => {
        if (!conditionAnswersInitialized && vehicleConditionInfo.conditionQuestions.length > 0) {
            initialize(vehicleConditionInfo.conditionQuestions);
            setConditionAnswersInitialized(true);
        }
    }, [conditionAnswersInitialized, vehicleConditionInfo.conditionQuestions]);

    useEffect(() => {
        if (isFeatureEnabled(testNames.QUESTION_COLORS) && !colorsRequested) {
            const getColors = () => {
                getExteriorColors().then(colors => {
                    colors = colors.sort((a, b) => {
                        return a.description.localeCompare(b.description);
                    });

                    setExteriorColors(colors);
                });

                getInteriorColors().then(colors => {
                    colors = colors.sort((a, b) => {
                        return a.description.localeCompare(b.description);
                    });

                    setInteriorColors(colors);
                });
            };

            getColors();
            setColorsRequested(true);
        }
    }, [colorsRequested, isFeatureEnabled]);

    const initialize = (conditionQuestions: IDynamicConditionQuestion[]) => {
        setVehicleConditionInfo({
            ...vehicleConditionInfo,
            conditionAnswers: initalizeConditionAnswers(conditionQuestions, vehicleConditionInfo.conditionAnswers),
        });
    };

    const resetVehicleInfo = useCallback(() => setVehicleInfo(vehicleInfoInitialState), []);

    const questionColors = useMemo(
        () => isFeatureEnabled(testNames.QUESTION_COLORS) && exteriorColors.length > 0 && interiorColors.length > 0,
        [isFeatureEnabled, exteriorColors, interiorColors]
    );

    const values = useMemo(
        () => ({
            exteriorColors,
            featureInfo,
            featureLookupInfo,
            interiorColors,
            questionColors,
            resetVehicleInfo,
            setFeatureInfo,
            setFeatureLookupInfo,
            setVehicleConditionInfo,
            setVehicleInfo,
            vehicleConditionInfo,
            vehicleInfo,
        }),
        [
            exteriorColors,
            featureInfo,
            featureLookupInfo,
            interiorColors,
            questionColors,
            resetVehicleInfo,
            setFeatureInfo,
            setFeatureLookupInfo,
            setVehicleConditionInfo,
            setVehicleInfo,
            vehicleConditionInfo,
            vehicleInfo,
        ]
    );

    return <VehicleInfoContext.Provider value={values}>{children}</VehicleInfoContext.Provider>;
};

export function useVehicleInfo() {
    return useContext(VehicleInfoContext);
}

export default VehicleInfoProvider;
