// Import before component to maintain LEGOS compatibility
import './index.scss';
import './polyfill';

import { themeConfig } from '@kmx/mui-resources';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createTheme, ThemeProvider } from '@mui/material';
import { deepmerge } from '@mui/utils';
import classNames from 'classnames';
import * as React from 'react';
import { useCallback, useState } from 'react';
import * as styles from './App.module.scss';
import InstantCashOffer from './components/InstantCashOffer';
import ClientInfoProvider, { IClientInfoContext } from './context/clientInfo';
import CustomerInfoProvider from './context/customerInfo';
import FeaturesProvider from './context/features';
import FormProvider from './context/formContext';
import OfferContextProvider from './context/offerContext';
import StoreInfoProvider from './context/storeInfo';
import VehicleInfoProvider from './context/vehicleInfo';
import { IVehicleInfo } from './types/IVehicleInfo';
import { IVinEligibility } from './types/IVinEligibility';
import { IVinMatchingStylesEligibility } from './types/IVinMatchingStyles';
import { getOriginPage } from './utils/analytics';

const EXT_DOMAINS_TO_IGNORE = ['fullstory.com', 'qualtrics.com', 'mpulse.net', 'google-analytics.com'];

const extendedTheme = createTheme(
    deepmerge(themeConfig, {
        breakpoints: {
            values: { xs: 0, sm: 440, md: 900, lg: 1200, xl: 1450 },
        },
        colorSchemes: {
            // This removes the dark color scheme.
            dark: undefined,
        },
        components: {
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontSize: themeConfig.typography.body2.fontSize,
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        fontSize: themeConfig.typography.body2.fontSize,
                    },
                },
            },
        },
        // Removes the dark color scheme https://mui.com/material-ui/customization/css-theme-variables/configuration/#disabling-css-color-scheme
        cssVariables: { disableCssColorScheme: true },
    })
);

export interface IAppProps {
    authRefresh: () => Promise<string>;
    clientFeatures: string[];
    clientFeaturesEventId?: string;
    clientInfo?: IClientInfoContext;
    customerEmail?: string;
    enabledFeatures: string[];
    extraQuestions?: string[];
    handleIneligible: boolean;
    hidden: boolean;
    offerId?: string;
    originPage?: string;
    renderIOForm: boolean;
    saveResult: boolean;
    startingMethod?: string;
    startQuote?: IVinEligibility;
    storeId?: string;
    vehicleInfo: IVehicleInfo;
    vehicleStyles?: IVinMatchingStylesEligibility;
}

const App: React.FC<IAppProps> = ({
    clientFeatures,
    clientFeaturesEventId,
    clientInfo,
    customerEmail,
    enabledFeatures,
    extraQuestions,
    handleIneligible,
    hidden,
    offerId,
    originPage,
    renderIOForm,
    saveResult,
    startingMethod,
    startQuote,
    storeId,
    vehicleInfo,
    vehicleStyles,
}) => {
    const [containerKey, setContainerKey] = useState(1); //everytime ICO is reset, this number increments

    //changing the container div's key will throw everything away and start over, resetting all of the inital state for all elements.
    const reset = useCallback(() => {
        setContainerKey(prev => prev + 1);
        window.dispatchEvent(new CustomEvent('icoReset', { detail: 'ICO Reset' }));
    }, []);

    const useInitialProps = containerKey === 1;

    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: `${process.env.INSTRUMENTATION_KEY}`,
            extensions: [reactPlugin],
        },
    });
    appInsights.loadAppInsights();
    appInsights.addDependencyInitializer((details: any) => {
        if (EXT_DOMAINS_TO_IGNORE.some(e => details.item.target.includes(e)) && details.item.success === true) {
            return false;
        }
        return true;
    });

    return (
        <div key={containerKey} id="ico-container" className={classNames(styles.ico, { [styles.icoHidden]: hidden })}>
            <AppInsightsContext.Provider value={reactPlugin}>
                <FeaturesProvider
                    clientFeatures={clientFeatures}
                    clientFeaturesEventId={clientFeaturesEventId}
                    enabledFeatures={enabledFeatures}
                >
                    <VehicleInfoProvider
                        InitialVehicleInfo={useInitialProps ? { ...vehicleInfo, isComplete: false } : undefined}
                    >
                        <CustomerInfoProvider initialCustomerEmail={customerEmail}>
                            <ClientInfoProvider initialClientInfo={clientInfo}>
                                <StoreInfoProvider>
                                    <OfferContextProvider
                                        handleIneligible={handleIneligible}
                                        saveResult={saveResult}
                                        extraQuestions={extraQuestions}
                                    >
                                        <FormProvider startingMethod={startingMethod} originPage={originPage}>
                                            <ThemeProvider theme={extendedTheme}>
                                                <InstantCashOffer
                                                    enabledFeatures={enabledFeatures}
                                                    offerId={useInitialProps ? offerId : undefined}
                                                    originPage={getOriginPage(originPage)}
                                                    renderIOForm={renderIOForm}
                                                    reset={reset}
                                                    startQuote={useInitialProps ? startQuote : undefined}
                                                    storeId={storeId}
                                                    vehicleStyles={useInitialProps ? vehicleStyles : undefined}
                                                />
                                            </ThemeProvider>
                                        </FormProvider>
                                    </OfferContextProvider>
                                </StoreInfoProvider>
                            </ClientInfoProvider>
                        </CustomerInfoProvider>
                    </VehicleInfoProvider>
                </FeaturesProvider>
            </AppInsightsContext.Provider>
        </div>
    );
};

export default App;
