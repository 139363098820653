import { Checkbox } from '@kmx/legos-react-checkbox';
import { Select } from '@kmx/legos-react-select';
import { TextField } from '@kmx/legos-react-text-field';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FORM_FIELD_NAMES } from '../../../constants';
import { useCustomerInfo } from '../../../context/customerInfo';
import { testNames, useFeatures } from '../../../context/features';
import { useFormContext } from '../../../context/formContext';
import { useVehicleInfo } from '../../../context/vehicleInfo';
import { IDynamicAnswer, IDynamicConditionAnswer, IDynamicConditionQuestion } from '../../../types/IConditionQuestion';
import { IQuote } from '../../../types/IQuote';
import { formatSeperatedInteger } from '../../../utils/format';
import {
    countConditionQuestionErrors,
    getEmailAddressValidationError,
    getMileageValidationError,
} from '../../../utils/validation';
import IcoRadio from '../../IcoRadio/IcoRadio';
import KbbConditionDialog from '../../KbbConditionDialog';
import ValidatedArea from '../../ValidatedArea';
import ConditionQuestionsDynamic from '../ConditionQuestionsDynamic';
import CardQuestion from './CardQuestion';
import MileageInput from './MileageInput';

import * as styles from './ConditionsBody.module.scss';

interface IConditionsBodyProps {
    onCompleteChange?(isComplete: boolean): void; // Injected by the AccordionStepper
    allValidationTriggered: boolean;
    readonly: boolean;
    includeKbbConditionQuestion: boolean;
    submitIcoOnEmailEnter?: () => void;
    previousOffer: IQuote;
}

const ConditionsBody: React.FC<IConditionsBodyProps> = ({ onCompleteChange, ...props }) => {
    const { customerInfo, setCustomerInfo } = useCustomerInfo();
    const {
        exteriorColors,
        interiorColors,
        setVehicleConditionInfo,
        questionColors,
        vehicleConditionInfo,
        vehicleInfo,
    } = useVehicleInfo();
    const [mileage, setMileage] = useState(formatSeperatedInteger(vehicleConditionInfo.mileage || vehicleInfo.mileage));
    const [email, setEmail] = useState(customerInfo.email);
    const [showKbbDialog, setShowKbbDialog] = useState(false);
    const [emailWasTouched, setEmailWasTouched] = useState(false);
    const [mileageWasTouched, setMileageWasTouched] = useState(false);
    const { isFeatureEnabled } = useFeatures();
    const { formMetadata, setFormMetadata } = useFormContext();

    // TODO - Clean up the validation to not have `useEffect` triggering validation.
    const [exteriorColor, setExteriorColor] = useState<string>('');
    const [interiorColor, setInteriorColor] = useState<string>('');

    useEffect(() => {
        if (email) {
            setEmailWasTouched(true);
        }
        setCustomerInfo(prev => ({ ...prev, email }));
    }, [email]);

    useEffect(() => {
        updatedAnswers(vehicleConditionInfo.conditionAnswers);
    }, [mileage, email, exteriorColor, interiorColor, props.allValidationTriggered]);

    const mileageError = useMemo(() => getMileageValidationError(mileage), [mileage]);

    const getErrorCount = useCallback((): number => {
        let count = 0;
        if (isFeatureEnabled(testNames.CONDITION_QUESTION_EMAIL)) {
            count += getEmailAddressValidationError(email) ? 1 : 0;
        }

        if (questionColors) {
            count += exteriorColor ? 0 : 1;
            count += interiorColor ? 0 : 1;
        }

        count += mileageError ? 1 : 0;
        return count;
    }, [email, mileageError, interiorColor, exteriorColor, isFeatureEnabled]);

    const getValidationErrorCount = useCallback(
        (answers: IDynamicConditionAnswer[]) => {
            let count = getErrorCount();
            count += countConditionQuestionErrors(vehicleConditionInfo.conditionQuestions, answers);
            return count;
        },
        [getErrorCount, vehicleConditionInfo.conditionQuestions]
    );

    const updatedAnswers = useCallback(
        (answers: IDynamicConditionAnswer[]): void => {
            const errorCount = getValidationErrorCount(answers);
            const isComplete = errorCount === 0;
            onCompleteChange(isComplete);
            setVehicleConditionInfo(prev => ({
                ...prev,
                conditionAnswers: answers,
                isComplete,
                mileage,
                errorCount,
                interiorColor,
                exteriorColor,
            }));
        },
        [mileage, getValidationErrorCount, setVehicleConditionInfo, onCompleteChange]
    );

    const closeKbbDialog = () => setShowKbbDialog(false);

    const validatedEmailOnEnter = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && getEmailAddressValidationError(email) === null) {
            props.submitIcoOnEmailEnter();
        }
    };

    const emailValidationWasTriggered = emailWasTouched || props.allValidationTriggered;

    const toggleSellingOrTradingAnswer = (question: IDynamicConditionQuestion, answerId: number) => {
        setCustomerInfo(prev => ({ ...prev, sellingOrTrading: answerId.toString() }));
    };

    const renderSingleSelectQuestion = (id: string, question: IDynamicConditionQuestion) => {
        return (
            <fieldset id={id} className="kmx-flex-wrapper">
                <ValidatedArea overlayIcon={false} invalid={false} validationMessage="Please make a selection below">
                    <legend>{question.description}</legend>
                </ValidatedArea>
                {question.subDescription && <p className="kmx-typography--fine-print">{question.subDescription}</p>}
                <div>
                    {question.answers.map((answer: IDynamicAnswer) => {
                        return (
                            <IcoRadio
                                key={answer.id}
                                id={`${question.id.toString()}-${answer.id.toString()}`}
                                name={question.id.toString()}
                                value={answer.id.toString()}
                                disabled={props.readonly}
                                selected={answer.id.toString() === customerInfo.sellingOrTrading}
                                onChange={() => {
                                    toggleSellingOrTradingAnswer(question, answer.id);
                                }}
                                label={answer.description}
                            />
                        );
                    })}
                </div>
            </fieldset>
        );
    };

    const sellingOrTradingQuestion = () => {
        if (isFeatureEnabled(testNames.SELLING_OR_TRADING)) {
            return (
                <div>
                    {renderSingleSelectQuestion(testNames.SELLING_OR_TRADING, {
                        displayOrder: 99999,
                        answerType: 'single',
                        id: 99999,
                        description: 'Selling or trading in?',
                        answers: [
                            {
                                id: 0,
                                description: 'Selling only',
                            },
                            {
                                id: 1,
                                description: 'Trading in',
                            },
                            {
                                id: 2,
                                description: 'Not sure',
                            },
                        ],
                    })}
                </div>
            );
        } else {
            return <></>;
        }
    };

    return (
        <div id="icoConditionsBody" className={styles.container}>
            {showKbbDialog && <KbbConditionDialog closeKbbDialog={closeKbbDialog} />}
            {props.includeKbbConditionQuestion && (
                <CardQuestion
                    readOnly={props.readonly}
                    conditionValidationTriggered={props.includeKbbConditionQuestion}
                    includeKbbConditionQuestion={props.includeKbbConditionQuestion}
                    setShowKbbDialog={setShowKbbDialog}
                    presetAnswer={props.previousOffer?.metaData?.selectedKbbCondition}
                />
            )}

            {questionColors && (
                <div className={styles.colorSection}>
                    <h3 className="kmx-typography--headline-2">Color</h3>
                    <fieldset>
                        <ValidatedArea
                            overlayIcon={true}
                            invalid={props.allValidationTriggered && !exteriorColor}
                            validationMessage="Please make a selection above"
                        >
                            <Select
                                label="Exterior Color"
                                id="ico-exterior-color"
                                name={FORM_FIELD_NAMES.conditionsBody.exteriorColor}
                                disabled={props.readonly}
                                value={exteriorColor}
                                onChange={event => setExteriorColor(event.target.value)}
                                options={exteriorColors.map(c => {
                                    return {
                                        disabled: false,
                                        label: c.description,
                                        value: c.code,
                                    };
                                })}
                            />
                        </ValidatedArea>
                    </fieldset>
                    <fieldset>
                        <ValidatedArea
                            overlayIcon={true}
                            invalid={props.allValidationTriggered && !interiorColor}
                            validationMessage="Please make a selection above"
                        >
                            <Select
                                label="Interior Color"
                                id="ico-interior-color"
                                name={FORM_FIELD_NAMES.conditionsBody.interiorColor}
                                disabled={props.readonly}
                                value={interiorColor}
                                onChange={event => setInteriorColor(event.target.value)}
                                options={interiorColors.map(c => {
                                    return {
                                        disabled: false,
                                        label: c.description,
                                        value: c.code,
                                    };
                                })}
                            />
                        </ValidatedArea>
                    </fieldset>
                </div>
            )}

            <MileageInput
                readOnly={props.readonly}
                value={mileage}
                setValue={setMileage}
                wasTouched={mileageWasTouched || props.allValidationTriggered}
                setWasTouched={setMileageWasTouched}
            />

            <h3 className="kmx-typography--headline-2">Additional Info</h3>
            <ConditionQuestionsDynamic
                allValidationTriggered={props.allValidationTriggered}
                readonly={props.readonly}
                updatedAnswers={updatedAnswers}
            />

            {sellingOrTradingQuestion()}

            {isFeatureEnabled(testNames.CONDITION_QUESTION_EMAIL) && (
                <div>
                    <h3 className="kmx-typography--headline-2">Offer delivery</h3>
                    <h3 className="kmx-typography--body-2">
                        Please provide an email address and we&apos;ll send a copy of your offer.
                    </h3>
                    <fieldset>
                        <TextField
                            label="Preferred email"
                            name={FORM_FIELD_NAMES.conditionsBody.email}
                            value={email}
                            type="email"
                            autoComplete="email"
                            onChange={e => setEmail(e.target.value.replace(/\s/, ''))}
                            disabled={props.readonly}
                            onBlur={() => setEmailWasTouched(true)}
                            validationStatus={
                                !emailValidationWasTriggered
                                    ? null
                                    : getEmailAddressValidationError(email)
                                      ? 'invalid'
                                      : 'valid'
                            }
                            helperText={emailValidationWasTriggered ? getEmailAddressValidationError(email) : null}
                            maxLength={50}
                            inputMode={'email'}
                            inputProps={{ onKeyPress: (event: KeyboardEvent) => validatedEmailOnEnter(event) }}
                        />
                        {isFeatureEnabled(testNames.TRACK_VALUE) && (
                            <Checkbox
                                checked={formMetadata.marketValueEmailOptIn}
                                disabled={props.readonly}
                                onChange={() =>
                                    setFormMetadata(prev => ({
                                        ...prev,
                                        marketValueEmailOptIn: !prev.marketValueEmailOptIn,
                                    }))
                                }
                                label="Track your value"
                                helperText="Send me regular updates on the value of my vehicle"
                            />
                        )}
                    </fieldset>
                </div>
            )}
        </div>
    );
};

export default ConditionsBody;
