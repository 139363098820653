import { Button as KmxButton } from '@kmx/legos-react-button';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import classNames from 'classnames';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { getWalletPassV2 } from '../../api/walletPass';
import BellIcon from '../../assets/BellIcon.svg';
import ExternalLinkIcon from '../../assets/ExternalLinkIcon.svg';
import Swoosh from '../../assets/swoosh.png';
import WalletPassImage from '../../assets/wallet_pass.svg';
import { ORIGIN_PAGES } from '../../constants';
import { useClientInfo } from '../../context/clientInfo';
import { featureName, testNames, useFeatures } from '../../context/features';
import { useOfferContext } from '../../context/offerContext';
import { useStoreInfo } from '../../context/storeInfo';
import { updateSourceSection, withAnalyticsClickTracking } from '../../utils/analytics';
import { isLoggedIn } from '../../utils/cookie';
import { formatSeperatedInteger } from '../../utils/format';
import { goToCarSearch, goToMyKmx, goToOfferCertificate, goToPreQual, setAppointment } from '../../utils/redirect';
import usaStates from '../../utils/usaStates';
import Card from '../Card';
import ICODialog from '../ICODialog';
import KbbValuation from './KbbValuation';
import * as styles from './Offer.module.scss';

const Button = withAnalyticsClickTracking(KmxButton);

interface IOfferProps {
    className?: string;
    vin: string;
    originPage?: string;
    userId: string;
    storeId?: string;
    onEditVehicleClick: () => void;
}

const OFFER_PAGE_ID = 'Offer Page';

const Offer: React.FC<IOfferProps> = props => {
    // const { customerInfo } = useCustomerInfo();
    const appInsights = useAppInsightsContext();
    const [continueModalOpen, setContinueModalOpen] = useState(false);
    const { offer, kbb } = useOfferContext();
    const { clientInfo } = useClientInfo();
    const { isFeatureEnabled } = useFeatures();
    const { storeInfo } = useStoreInfo();
    const stateName = React.useMemo(
        () => usaStates.find(state => state.value === storeInfo.state).label,
        [storeInfo.state]
    );

    useEffect(() => {
        appInsights.trackEvent({
            name: 'Offer page - viewed',
            properties: {
                quoteId: offer.code,
                vin: props.vin,
            },
        });
        // Update source section when the offer page loads
        updateSourceSection('Offer Page');
    }, []);

    const ctaValues = {
        headline: `We're ready to buy your car`,
        description: `Bring your car and offer to any CarMax store.\nWe'll confirm your car's condition and pay you on the spot.`,
        buttons: [
            { header: 'Continue', content: '' },
            ...(props.userId ? [] : [{ header: 'Save this offer', content: '' }]),
        ],
    };

    if (!offer) {
        return null;
    }

    const continueClick = () => {
        if (isFeatureEnabled(testNames.TRADE_IN_CTA)) {
            setContinueModalOpen(true);
        } else {
            setAppointment(props.originPage, clientInfo, offer);
        }
    };

    const getHeadlineText = () => {
        if (props.originPage === ORIGIN_PAGES.inStore) {
            return 'Check-in to Continue';
        }

        return ctaValues.headline;
    };

    const getDescriptionText = () => {
        if (props.originPage === ORIGIN_PAGES.inStore) {
            return `Check-in to have your offer verified or if you have any questions.\nWe'll confirm your car's conditions and pay you on the spot.`;
        }

        return ctaValues.description;
    };

    const shopCars = () => {
        const shopUrl = isLoggedIn() ? `${process.env.LEADS_URL}mycarmax/saved-cars` : `${process.env.LEADS_URL}cars`;

        window.location.href = shopUrl;
    };

    const renderTradeIn = () => {
        return (
            <div className={styles.shopInfoContainer}>
                <h3 className="kmx-typography--display-3">Save more when you trade in your car in {stateName}</h3>
                {isFeatureEnabled(featureName.TRADE_IN_TAX) && (
                    <div className={styles.tradeInTaxContainer}>
                        <BellIcon />
                        <p className={'kmx-typography--display-2'}>Potential Sales Tax Savings</p>
                        <p className="kmx-typography--display-3">{offer.metaData.tax}</p>
                    </div>
                )}
                <p className="kmx-typography--body-2">
                    By trading in, you can save up to {offer.metaData.tax} in sales taxes on your purchase.
                </p>
                <Button level="primary" className={styles.shopCta} onClick={shopCars}>
                    Shop and Save
                    <ExternalLinkIcon className={styles.shopCtaIcon} />
                </Button>
            </div>
        );
    };

    const continueDialog = (
        <ICODialog closeDialog={() => setContinueModalOpen(false)} showDefaultCloseButton={false}>
            <div>
                <h3 className="kmx-typography--display-4">What do you want to do next?</h3>
                <Card
                    id="next-steps-schedule-appt"
                    header="Schedule my appointment"
                    content="Bring your car and offer to any CarMax store. We'll confirm your car's condition and pay you on the spot."
                    onClick={() => setAppointment(props.originPage, clientInfo, offer)}
                />
                <Card
                    id="next-steps-prequal"
                    header="Get pre-qualified"
                    content="Get financing results without impacting your credit and see personalized terms on our cars."
                    onClick={goToPreQual}
                />
                <Card
                    id="next-steps-shop"
                    header="Shop for my next car"
                    content="Now that you know what you will recieve for your trade-in, use it to shop for another vehicle."
                    onClick={goToCarSearch}
                />
            </div>
        </ICODialog>
    );

    return (
        <div data-qa="offer-page" id={OFFER_PAGE_ID}>
            {continueModalOpen && continueDialog}
            <div className={classNames('kmx-ico-offer', styles.offerContainer, props.className)}>
                <div className={classNames('kmx-ico-offer-offerinfo', styles.offerInfo)}>
                    <h3 className="kmx-typography--display-4">Your Offer</h3>
                    <h1 data-qa="offer-amount" className="kmx-typography--display-6">
                        ${formatSeperatedInteger(offer.valuation)}
                    </h1>
                    <img className={styles.swoosh} src={process.env.BASE_URL + Swoosh} role="none" />
                    <p className={classNames(styles.offerCode, 'kmx-typography--display-3')}>
                        Offer code: {offer.code}
                    </p>
                    <p className={classNames(styles.expiration, 'kmx-typography--body-1')}>
                        Valid through {offer.expiresDisplayDate}
                    </p>
                    <div className={styles.nextActionsContainer}>
                        {isFeatureEnabled(testNames.WALLET_PASS) && isIOS && isMobile && (
                            <div id="wallet-pass">
                                <button
                                    id="wallet-pass"
                                    className={styles.walletPassButton}
                                    onClick={() => getWalletPassV2(offer.code)}
                                >
                                    <WalletPassImage className={styles.walletPass} />
                                    wallet-pass
                                </button>
                            </div>
                        )}
                        <Button level="primary" className={styles.offerCta} onClick={continueClick}>
                            Continue
                        </Button>
                    </div>
                </div>
                {offer?.metaData?.offerDisclaimer && (
                    <div className={styles.offerDisclaimer}>
                        <p className={classNames('kmx-typography--body-2', styles.disclaimerText)}>
                            {offer.metaData.offerDisclaimer}
                        </p>
                    </div>
                )}
                {kbb && <KbbValuation />}
                <>
                    {isFeatureEnabled(featureName.TRADE_IN_TAX) && offer.metaData.tax ? renderTradeIn() : <></>}
                    <div
                        id="kmx-ico-offer-appointment"
                        className={classNames('kmx-ico-offer-appointment', styles.appointmentContainer)}
                    >
                        <h3 className={classNames('kmx-typography--display-3', styles.headline)}>
                            {getHeadlineText()}
                        </h3>
                        <p className={classNames('kmx-typography--body-2', styles.description)}>
                            {getDescriptionText()}
                        </p>
                        {!props.userId && (
                            <Button
                                id="ico-save-offer"
                                onClick={() => goToMyKmx(offer)}
                                level={
                                    isFeatureEnabled(testNames.EDIT_VEHICLE_A) ||
                                    isFeatureEnabled(testNames.EDIT_VEHICLE_C)
                                        ? 'secondary'
                                        : 'tertiary'
                                }
                                className={styles.saveOffer}
                            >
                                {'Save this offer'}
                            </Button>
                        )}
                        {isFeatureEnabled(testNames.EDIT_VEHICLE_A) && (
                            <div id="edit-vehicle">
                                <Button
                                    onClick={props.onEditVehicleClick}
                                    level="tertiary"
                                    className={styles.editVehicle}
                                >
                                    Edit car details
                                </Button>
                            </div>
                        )}
                        {isFeatureEnabled(testNames.EDIT_VEHICLE_C) && (
                            <Button
                                onClick={() => goToOfferCertificate(offer)}
                                level="tertiary"
                                className={styles.viewOffer}
                            >
                                View offer details
                            </Button>
                        )}
                    </div>
                </>
            </div>
        </div>
    );
};

export default Offer;
