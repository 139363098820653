import { useCallback, useMemo, useState } from 'react';
import { useOfferContext } from '../../context/offerContext';
import { startQuoteV2 } from '../../api/quote';
import { IVehicleInfo } from '../../types/IVehicleInfo';
import { IVinEligibility } from '../../types/IVinEligibility';
import { IStartQuote } from '../../types/IStartQuote';
import useTriggerIneligibleEvent from './useTriggerIneligibleEvent';
import { useFormContext } from '../../context/formContext';
// import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import { getCookieValue } from '../../utils/cookie';
import { useCustomerInfo } from '../../context/customerInfo';
import { useVehicleInfo } from '../../context/vehicleInfo';
import { useStoreInfo } from '../../context/storeInfo';
import { activeStoreAppraisal, zipCodeDecodeError } from '../../utils/offerEvents';
import { useFeatures } from '../../context/features';


interface ITriggerStartQuote {
    vehicleData: IVehicleInfo;
    pageType?: string;
}

interface IUseStartQuote {
    startQuote?: IVinEligibility;
}

export default function useStartQuote(props: IUseStartQuote) {
    const { trigger: vehicleIneligible } = useTriggerIneligibleEvent();
    const [eligibilityResponses, setEligibilityResponses] = useState<IVinEligibility>({ ...props.startQuote });
    const { setIneligibleReason, setIneligible, setQuoteId, extraQuestions, setStoreAppraisal } = useOfferContext();
    const { setStoreInfo } = useStoreInfo();
    const { setFormMetadata } = useFormContext();
    const { setCustomerInfo } = useCustomerInfo();
    const { setVehicleConditionInfo, setFeatureLookupInfo } = useVehicleInfo();

    // temporary SSE logging *************************

    // const { quoteId } = useOfferContext();
    // const appInsights = useAppInsightsContext();
    // const track = useTrackEvent(appInsights, 'sse: not supported', { quoteId: quoteId });

    // useEffect((() => {
    //     if (quoteId != null) {
    //         //console.log('quoteId = ', quoteId, "instrumentation key = ", process.env.INSTRUMENTATION_KEY);
    //         if (!window.EventSource) {
    //             track({ quoteId: quoteId });
    //         }
    //     }
    // }), [quoteId]);

    // **********************************************

    const { icoFeatures } = useFeatures();

    const start = useCallback(
        async ({ vehicleData, pageType }: ITriggerStartQuote): Promise<IStartQuote | null> => {
            if (eligibilityResponses[vehicleData.vin] && eligibilityResponses[vehicleData.vin].startQuote?.isEligible) {
                return eligibilityResponses[vehicleData.vin];
            }
            const startQuoteResponse = await startQuoteV2(
                vehicleData.vin,
                vehicleData.plate,
                vehicleData.state,
                getCookieValue('KmxMyKmx_0', 'userid'),
                vehicleData.zipcode,
                extraQuestions,
                icoFeatures.join()
            );
            setEligibilityResponses({ ...eligibilityResponses, vin: startQuoteResponse });

            if (window?.FS && startQuoteResponse?.startQuote?.quoteId) {
                window?.FS?.setUserVars({
                    vin: vehicleData.vin,
                    quoteId: startQuoteResponse.startQuote.quoteId,
                });
            }

            if (
                startQuoteResponse &&
                startQuoteResponse.store &&
                startQuoteResponse.isSuccess &&
                startQuoteResponse.startQuote?.isEligible
            ) {
                setQuoteId(startQuoteResponse.startQuote.quoteId);
                setVehicleConditionInfo(prev => ({
                    ...prev,
                    conditionQuestions: startQuoteResponse.conditionQuestions,
                }));
                if (startQuoteResponse?.customerProfile) {
                    setCustomerInfo({
                        ciamId: startQuoteResponse.customerProfile.ciamId,
                        email: startQuoteResponse.customerProfile.email,
                    });
                }
                setStoreInfo(startQuoteResponse.store);
                setFeatureLookupInfo(startQuoteResponse.skus);
            } else if (!startQuoteResponse.store) {
                zipCodeDecodeError(vehicleData.zipcode, startQuoteResponse.faults[0] || null);
            } else if (startQuoteResponse.activeStoreAppraisal) {
                setStoreAppraisal(startQuoteResponse.activeStoreAppraisal);
                activeStoreAppraisal(true, vehicleData, startQuoteResponse.activeStoreAppraisal);
            } else {
                // Check if we have created a quote.
                if (startQuoteResponse && startQuoteResponse.startQuote && startQuoteResponse.startQuote.quoteId) {
                    setQuoteId(startQuoteResponse.startQuote.quoteId);
                }

                setIneligibleReason(startQuoteResponse?.startQuote?.reason);
                setIneligible(true);

                // ymm-ineligible or VIN eligibility
                vehicleIneligible({
                    reason: startQuoteResponse?.startQuote?.reason,
                    pageType,
                });
            }

            return startQuoteResponse;
        },
        [vehicleIneligible, setIneligible, setIneligibleReason, setQuoteId, setFormMetadata]
    );

    return useMemo(() => ({ start }), [start]);
}
