import { IColor } from '../types/IVehicleInfo';
import { request } from './request';

export const getExteriorColors = async (): Promise<IColor[]> => {
    return request('GET', 'lookup/exterior-color').then(response => {
        if (response.status == 200 && response.data) {
            return response.data;
        } else {
            return [];
        }
    });
};

export const getInteriorColors = async (): Promise<IColor[]> => {
    return request('GET', 'lookup/interior-color').then(response => {
        if (response.status == 200 && response.data) {
            return response.data;
        } else {
            return [];
        }
    });
};
