/* eslint react/prop-types: 0 */
import * as React from 'react';
import { ReactNode, useState, createContext, useContext, useMemo } from 'react';
import { IStoreInfo } from '../types/IStore';
import { UseState } from '../types/UseStateType';

const CustomerInfoContext = createContext<{
    customerInfo: ICustomerInfoContext;
    setCustomerInfo: UseState<ICustomerInfoContext>;
    storeInfo: IStoreInfo;
    setStoreInfo: UseState<IStoreInfo>;
}>(null);

interface ICustomerInfoProviderProps {
    initialCustomerEmail?: string;
    children: ReactNode;
}

export interface ICustomerInfoContext {
    ciamId?: string;
    email?: string;
    store?: IStoreInfo;
    sellingOrTrading?: string;
}

export const initialState: ICustomerInfoContext = {
    ciamId: null,
    email: '',
    store: null,
};

const CustomerInfoProvider: React.FC<ICustomerInfoProviderProps> = ({ initialCustomerEmail = '', children }) => {
    const [customerInfo, setCustomerInfo] = useState({ ...initialState, email: initialCustomerEmail });
    const [storeInfo, setStoreInfo] = useState<IStoreInfo>(null);

    const values = useMemo(
        () => ({ customerInfo, setCustomerInfo, storeInfo, setStoreInfo }),
        [customerInfo, storeInfo]
    );

    return <CustomerInfoContext.Provider value={values}>{children}</CustomerInfoContext.Provider>;
};

export function useCustomerInfo() {
    return useContext(CustomerInfoContext);
}

export default CustomerInfoProvider;
