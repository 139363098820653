import { ORIGIN_PAGES } from '../constants';
import { IClientInfoContext } from '../context/clientInfo';
import { IOffer } from '../types/IOfferResponse';
import { IActiveStoreAppraisal } from '../types/IStartQuote';

const ineligibleScheduleAppointmentLink = process.env.LEADS_URL + 'sell-my-car?op=ICO_Ineligible#AppointmentForm';

export const setAppointment = (
    originPage: string,
    clientInfo: IClientInfoContext,
    offer: IOffer | IActiveStoreAppraisal
) => {
    let apptUrl = null;
    const actualOffer = offer as IOffer;

    if (originPage === ORIGIN_PAGES.inStore) {
        const queryStringParamKiosk = clientInfo?.inStore?.kiosk != null ? `&kiosk=${clientInfo?.inStore?.kiosk}` : '';
        apptUrl = `${process.env.APPRAISALCHECKIN_URL}?storeId=${offer.storeId}&offerCode=${offer.code}${queryStringParamKiosk}`;
    }
    if (actualOffer?.appointmentUri && actualOffer?.appointmentUri !== '' && originPage && originPage.length > 0) {
        apptUrl = `${actualOffer.appointmentUri}&originPage=${originPage}`;
    } else {
        apptUrl = `${process.env.LEADS_URL}appraisal-checkout?storeid=${offer.storeId}&appraisalCode=${
            offer.code
        }${originPage && originPage.length > 0 ? '&originpage=' + originPage : ''}`;
    }

    window.open(apptUrl, originPage === ORIGIN_PAGES.inStore ? '_self' : '_blank');
};

export const goToSurvey = (offer: IOffer, thumbs: string) => {
    window.open(`${offer.qualtricsUrl}&Thumbs=${thumbs}`, '_blank');
};

export const goToMyKmx = (offer: IOffer) => {
    window.open(
        `${process.env.LEADS_URL}mycarmax/sign-in?returnUrl=${process.env.LEADS_URL}mycarmax/mycarmax/appraisals?offer=${offer.code}`,
        '_self'
    );
};

//add a half second delay before loading the page for the tag to fire for marketing
export const ineligibleScheduleAppointment = (): void => {
    setTimeout(() => {
        window.open(ineligibleScheduleAppointmentLink, '_self');
    }, 500);
};

//add a half second delay before loading the page for the tag to fire for marketing
export const picsyStart = (picsyUrl: string, adcode?: string): void => {
    setTimeout(() => {
        window.open(`${picsyUrl}${adcode && `?adcode=${adcode}`}`, '_self');
    }, 500);
};

export const goToOfferDetails = (offerCode: string) => {
    window.location.href = `${process.env.LEADS_URL}sell-my-car/offers/${offerCode}`;
};

export const goToOfferCertificate = (offer: IOffer) => {
    window.open(
        `${process.env.LEADS_URL}sell-my-car/utilities/offer-certificate/${offer.code}?storeId=${offer.storeId}`,
        '_blank'
    );
};

export const goToCarSearch = () => {
    setTimeout(() => {
        window.open(`${process.env.LEADS_URL}cars`, '_blank');
    }, 500);
};

export const goToPreQual = () => {
    setTimeout(() => {
        window.open(`${process.env.LEADS_URL}pre-qual/io_landing`, '_blank');
    }, 500);
};
