import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { Button as KmxButton } from '@kmx/legos-react-button';
import AccordionStepper, { Step } from './AccordionStepper';
import VehicleProfileSummary from './VehicleProfileSummary';
import VehicleBody from './Steps/VehicleBody';
import FeaturesBody from './Steps/FeaturesBody';
import ConditionsBody from './Steps/ConditionsBody';
import LoadingScreen from './LoadingScreen';
import { IOffer } from '../types/IOfferResponse';
import * as styles from './ICOStepper.module.scss';
import { trackICOSubmit, withAnalyticsClickTracking } from '../utils/analytics';
import ValidationSummary from './ValidationSummary';
import { testNames, useFeatures } from '../context/features';
import { scrollToElement, focusElement } from '../utils/scroll';
import { getKbbStylesV2 } from '../api/kbb';
import { getAppConfig } from './../utils/appConfig';
import { useVehicleInfo } from '../context/vehicleInfo';
import { useOfferContext } from '../context/offerContext';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import useRequestOffer from '../hooks/events/useRequestOffer';
import { IQuote } from '../types/IQuote';
import EditVehicleStep from './AccordionStepper/EditVehicleStep';
const Button = withAnalyticsClickTracking(KmxButton);

interface IStepperProps {
    originPage: string;
    getIneligiblePageAnalyticsValue: (offerResponse: IOffer) => string;
    previousOffer: IQuote;
    refreshOfferChanges: boolean;
    onEditVehicleClick: () => void;
}

const ICOStepper: React.FC<IStepperProps> = props => {
    const appInsights = useAppInsightsContext();
    const { vehicleInfo, featureInfo, questionColors, vehicleConditionInfo } = useVehicleInfo();
    const [readyToSubmit, setReadyToSubmit] = useState(false);
    const [allValidationTriggered, setAllValidationTriggered] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [includeKbbConditionQuestion, setIncludeKbbConditionQuestion] = useState(false);
    const { offer, quoteId } = useOfferContext();
    const [isConditionStepOpen, setIsConditionStepOpen] = useState(false);
    const { startSSE: requestQuoteSSE } = useRequestOffer();
    const { isFeatureEnabled } = useFeatures();
    const [conditionAnswersPrepopulated, setConditionAnswersPrepopulated] = useState(false);

    useEffect(() => {
        const isAllComplete = vehicleInfo.isComplete && featureInfo.isComplete && vehicleConditionInfo.isComplete;
        setReadyToSubmit(isAllComplete);
        if (
            isAllComplete &&
            props.refreshOfferChanges === false &&
            !submitting &&
            conditionAnswersPrepopulated &&
            !offer
        ) {
            submitClick();
        }
    }, [vehicleInfo, featureInfo, vehicleConditionInfo]);

    const submitClick = async () => {
        appInsights.trackEvent({
            name: 'ICO Stepper - submit click',
            properties: {
                quoteId,
                timestamp: new Date().toISOString(),
                vin: vehicleInfo.vin,
            },
        });

        if (!readyToSubmit) {
            // The form is invalid - trigger all validation
            setAllValidationTriggered(true);
            return;
        }

        // The form is valid - start submitting
        trackICOSubmit(props.originPage, vehicleInfo.vin, quoteId);
        setSubmitting(true);

        await requestQuoteSSE({ getIneligiblePageAnalyticsValue: props.getIneligiblePageAnalyticsValue });
    };

    //SSE only, but doesn't hurt to set it again for the normal case.
    useEffect(() => {
        setSubmitting(false);
    }, [offer]);

    const initializeThirdPartyValuation = useCallback(async (): Promise<void> => {
        //minor optimization, no need to re-fetch styles and enable KBB or Edmunds if one is already set
        if (includeKbbConditionQuestion || offer) return;
        let styles = null;
        if (isFeatureEnabled(testNames.KBB_VALUATION)) {
            styles = await getKbbStylesV2(vehicleInfo.vin);
            setIncludeKbbConditionQuestion(styles && styles.vinResults.length === 1);
        }
    }, [includeKbbConditionQuestion, vehicleInfo.vin, isFeatureEnabled]);

    useEffect(() => {
        if (props.previousOffer && !conditionAnswersPrepopulated && vehicleConditionInfo.conditionAnswers.length > 0) {
            setConditionAnswersPrepopulated(true);
            setAllValidationTriggered(true);
        }
    }, [vehicleConditionInfo.conditionAnswers]);

    const defaultStepExpanded = useCallback(
        (validateAllOnTouch: boolean) =>
            (stepIsDisabled: boolean): Promise<void> =>
                new Promise((resolve, reject) => {
                    setIsConditionStepOpen(false);

                    // Trigger/reset validation when changing steps
                    if (validateAllOnTouch) {
                        setAllValidationTriggered(stepIsDisabled);
                    }

                    if (stepIsDisabled) reject();
                    else resolve(null);
                }),
        []
    );

    const onMileageAndConditionsExpand = useCallback((): void => {
        setIsConditionStepOpen(true);

        if (!includeKbbConditionQuestion) {
            if (questionColors) {
                focusElement('#select-ico-exterior-color', 1);
            } else {
                focusElement('.kmx-ico-step-mileage-condition input', 1);
            }
        }

        if (!offer && !getAppConfig().disableScroll) {
            setTimeout(() => {
                scrollToElement('.kmx-ico-step-mileage-condition', false);
            }, 1);
        }
    }, [includeKbbConditionQuestion, offer]);

    const onVehicleProfileAttemptExpand = useCallback(
        async (stepIsDisabled: boolean): Promise<void> => {
            if (stepIsDisabled) throw null;
            setIsConditionStepOpen(false);
            await initializeThirdPartyValuation();
            return;
        },
        [initializeThirdPartyValuation]
    );

    return (
        <>
            <div className={styles.icoStepperParent}>
                <div
                    className={classNames(
                        styles.icoStepperContainer,
                        { [styles.offer]: offer },
                        { [styles.inProgress]: !offer },
                        'kmx-ico-stepper'
                    )}
                >
                    {!(isFeatureEnabled(testNames.EDIT_VEHICLE_C) && offer) && (
                        <AccordionStepper hostControlledStepExpansion={true} allCollapsed={submitting || !!offer}>
                            <Step
                                id="Vehicle Profile"
                                className="kmx-ico-step-vehicle"
                                header={<VehicleProfileSummary fullView={false} />}
                                autoProceedOnComplete
                                onAttemptStepExpand={defaultStepExpanded(true)}
                            >
                                <VehicleBody />
                            </Step>
                            <Step
                                id="Styles and Features"
                                className="kmx-ico-step-trim-features"
                                header={<>Style and features</>}
                                autoProceedOnComplete={false}
                                onAttemptStepExpand={onVehicleProfileAttemptExpand}
                            >
                                <FeaturesBody
                                    allValidationTriggered={allValidationTriggered}
                                    readonly={submitting || !!offer}
                                />
                            </Step>
                            <Step
                                id="Mileage and Condition"
                                className="kmx-ico-step-mileage-condition"
                                header={<>Mileage and condition</>}
                                autoProceedOnComplete={false}
                                onAttemptStepExpand={defaultStepExpanded(true)}
                                onStepExpanded={onMileageAndConditionsExpand}
                            >
                                <ConditionsBody
                                    allValidationTriggered={allValidationTriggered}
                                    readonly={submitting || !!offer}
                                    includeKbbConditionQuestion={includeKbbConditionQuestion}
                                    submitIcoOnEmailEnter={submitClick}
                                    previousOffer={props.previousOffer}
                                />
                            </Step>
                        </AccordionStepper>
                    )}
                    {offer &&
                        (isFeatureEnabled(testNames.EDIT_VEHICLE_B) || isFeatureEnabled(testNames.EDIT_VEHICLE_C)) && (
                            <EditVehicleStep onClick={props.onEditVehicleClick} />
                        )}
                    {!offer &&
                        allValidationTriggered &&
                        vehicleInfo.isComplete &&
                        (!featureInfo.isComplete || !vehicleConditionInfo.isComplete) && (
                            <ValidationSummary
                                className={styles.submitButtonContainer}
                                isConditionStepOpen={isConditionStepOpen}
                            />
                        )}
                    {!offer && (
                        <div className={classNames(styles.submitButtonContainer, 'kmx-ico-submit')}>
                            <Button
                                id="ico-continue-button"
                                level="primary"
                                className={classNames({ disabled: !readyToSubmit })}
                                onClick={submitClick}
                            >
                                Get My Offer
                            </Button>
                            <p className={classNames('kmx-typography--body-2', styles.reviewDetailsText)}>
                                Please double-check your details before submitting for an accurate offer.
                            </p>
                        </div>
                    )}
                </div>
            </div>
            {submitting && <LoadingScreen isSubmitting />}
        </>
    );
};

export default ICOStepper;
