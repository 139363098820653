import ArrowForward from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import MuiButton from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import MuiTab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { testNames, useFeatures } from '../../context/features';
import { useFormContext } from '../../context/formContext';
import { useVehicleInfo } from '../../context/vehicleInfo';
import useFormField from '../../hooks/useFormField';
import { withAnalyticsClickTracking } from '../../utils/analytics';
import { formatVin } from '../../utils/format';
import usaStates from '../../utils/usaStates';
import { isValidVin } from '../../utils/validation';
import useBreakpointWidth from '../../hooks/useBreakpointWidth';

const Button = withAnalyticsClickTracking(MuiButton);
const Tab = withAnalyticsClickTracking(MuiTab);

enum tabState {
    PLATE,
    VIN,
}

interface ILicenseOrVinProps {
    readOnly: boolean;
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

const LICENSE_PLATE_TAB_ID = 'LicensePlate';
const STATE_SELECT_ID = 'ico-vehicle-state';
const VIN_TAB_ID = 'VIN';

const LicenseOrVin: React.FC<ILicenseOrVinProps> = ({ readOnly, onSubmit }) => {
    const { vehicleInfo, setVehicleInfo } = useVehicleInfo();
    const { isFeatureEnabled } = useFeatures();
    const breakpoint = useBreakpointWidth();
    const {
        plateVinValid,
        setPlateDecodeError,
        plateDecodeError,
        setPlateVinValid,
        setFormMetadata,
        zipDecodeError,
        setZipDecodeError,
    } = useFormContext();
    const vin = useFormField(vehicleInfo.vin);
    const state = useFormField(vehicleInfo.state);
    const plate = useFormField(vehicleInfo.plate);
    const zipCode = useFormField(vehicleInfo.zipcode);
    const [activeTab, setActiveTab] = useState(tabState.PLATE);
    const [hideZipCode] = useState(vehicleInfo.zipcode?.length === 5);
    const isPlateTabActive = useMemo(() => activeTab === tabState.PLATE, [activeTab]);

    const headerVariant = useMemo(() => {
        switch (breakpoint) {
            case 'xs':
            case 'sm':
                return 'h6';
            case 'md':
            case 'lg':
            case 'xl':
            default:
                return 'h5';
        }
    }, [breakpoint]);

    useEffect(() => {
        if (isFeatureEnabled(testNames.DEFAULT_VIN)) {
            setActiveTab(tabState.VIN);
        }
    }, [isFeatureEnabled]);

    const zipValidationError = useMemo((): string => {
        if (zipCode.value == null || zipCode.value.length <= 0) return 'ZIP code must be entered';
        else if (zipCode.value.length != 5) return 'ZIP code must be 5 digits';
        else if (zipDecodeError) return zipDecodeError;
        else return null;
    }, [zipCode.value, zipDecodeError]);

    const vinValidationError = useMemo((): string => {
        if (vin.value == null || vin.value.length <= 0 || vin.value === '') return 'VIN must be entered';
        else if (vin.value.length != 17) return 'VIN must be a 17 character Vehicle Identification Number';
        else if (!isValidVin(vin.value))
            return 'VIN must be a valid Vehicle Identification Number. Please check that you have entered the VIN correctly.';
        else return null;
    }, [vin.value]);

    const plateValidationError = useMemo((): string => {
        if (plateDecodeError) return `We can't find that license plate. Try the car's VIN.`;
        if (plate.value == null || plate.value.replace(/[^0-9a-zA-Z]*/, '').length <= 0)
            // LP must be 1 or more characters, not including any spaces, dashes, etc.
            return 'License Plate must be entered';
        else return null;
    }, [plate.value, plateDecodeError]);

    const stateValidationError = useMemo((): boolean => {
        if (state.value == null || state.value.length <= 0 || state.value === '') return true;
        else return false;
    }, [state.value]);

    const isGetStartedButtonDisabled = useMemo(() => {
        if (!isPlateTabActive && !vinValidationError && !zipValidationError) {
            return false;
        } else if (!isPlateTabActive && (vinValidationError || zipValidationError)) {
            return true;
        } else if (
            isPlateTabActive &&
            !plateDecodeError &&
            !stateValidationError &&
            !plateValidationError &&
            !zipValidationError &&
            !plateDecodeError
        ) {
            return false;
        } else if (
            isPlateTabActive &&
            (plateDecodeError || stateValidationError || plateValidationError || zipValidationError)
        ) {
            return true;
        }

        return readOnly || !plateVinValid || plateDecodeError;
    }, [
        isPlateTabActive,
        plateVinValid,
        readOnly,
        zipValidationError,
        plateValidationError,
        vinValidationError,
        stateValidationError,
        plateDecodeError,
    ]);

    useEffect(() => {
        setFormMetadata(prev => ({ ...prev, startingMethod: isPlateTabActive ? 'LP' : 'VIN' }));
    }, [isPlateTabActive, setFormMetadata]);

    useEffect(() => {
        if (
            !zipValidationError &&
            (!vinValidationError || (state != null && state.value !== '' && !plateValidationError))
        ) {
            setPlateVinValid(true);
            setVehicleInfo(prev => ({
                ...prev,
                isComplete: true,
                zipcode: zipCode.value,
                vin: vin.value,
                plate: plate.value,
                state: state.value,
            }));
        } else {
            setPlateVinValid(false);
        }
    }, [
        vin,
        state,
        plate,
        zipCode,
        zipValidationError,
        plateValidationError,
        vinValidationError,
        setPlateVinValid,
        setVehicleInfo,
    ]);

    const plateOnChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            plate.setValue(event.target.value.replace(/[^0-9a-zA-Z \\-]/, ''));
            plate.setWasTouched(true);
            setPlateDecodeError(false);
        },
        [plate, setPlateDecodeError]
    );

    const plateStateOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        state.setValue(event.target.value);
        setPlateDecodeError(false);
    }, []);

    const onZipcodeChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            zipCode.setValue(e.target.value.replace(/[^0-9]/, ''));
            setZipDecodeError('');
        },
        [zipCode, setZipDecodeError]
    );

    const zipField = useMemo(() => {
        if (hideZipCode && !zipDecodeError) {
            return null;
        }
        const fieldId = isPlateTabActive ? 'ico-vehicle-zipCode' : 'ico-vehicle-vin-zipCode';
        return (
            <FormControl fullWidth>
                <TextField
                    autoComplete="off"
                    disabled={readOnly}
                    error={!!(zipCode.wasTouched && zipValidationError)}
                    helperText={zipCode.wasTouched && zipValidationError}
                    id={`text-${fieldId}`}
                    label="Zip Code"
                    name="zipCode"
                    onBlur={() => zipCode.setWasTouched(true)}
                    onChange={onZipcodeChange}
                    placeholder="Zip Code"
                    required
                    slotProps={{
                        htmlInput: { maxLength: 5, inputMode: 'numeric' },
                        inputLabel: { required: false },
                    }}
                    value={zipCode.value}
                />
            </FormControl>
        );
    }, [hideZipCode, isPlateTabActive, onZipcodeChange, readOnly, zipCode, zipDecodeError, zipValidationError]);

    const setTab = useCallback(
        (tab: tabState) => {
            setActiveTab(tab);
            setFormMetadata(prev => ({ ...prev, startingMethod: tab === tabState.PLATE ? 'LP' : 'VIN' }));
        },
        [setFormMetadata]
    );

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            // Reuse button disabled validation to return early if invalid
            if (isGetStartedButtonDisabled) return;
            onSubmit(event);
        },
        [isGetStartedButtonDisabled, onSubmit]
    );

    return (
        <Box
            sx={{
                mx: 'auto',
                width: '100%',
                maxWidth: {
                    xs: 416,
                    sm: 500,
                },
            }}
        >
            <Paper
                className="icoCardContainer"
                elevation={2}
                sx={{ borderRadius: 2, padding: 3, border: '1px solid', borderColor: 'divider' }}
            >
                <form onSubmit={handleSubmit}>
                    <Stack spacing={{ xs: 2, md: 3 }}>
                        <Typography variant={headerVariant} sx={{ fontSize: { sx: '1.25rem' } }}>
                            Get a real offer in minutes
                        </Typography>
                        <Tabs
                            id="ICO: Vehicle Profile"
                            value={activeTab}
                            onChange={(e, newTab) => setTab(newTab)}
                            variant="fullWidth"
                        >
                            <Tab id={`button-${LICENSE_PLATE_TAB_ID}`} label="Plate" value={tabState.PLATE} />
                            <Tab id={`button-${VIN_TAB_ID}`} label="VIN" value={tabState.VIN} />
                        </Tabs>
                        {activeTab === tabState.PLATE && (
                            <Box>
                                <Stack spacing={{ xs: 2, md: 3 }}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete="off"
                                            disabled={readOnly}
                                            error={!!(plate.wasTouched && !plate?.value) || plateDecodeError}
                                            helperText={(plate.wasTouched || plateDecodeError) && plateValidationError}
                                            id="ico-vehicle-licensePlate"
                                            label="License Plate"
                                            name="licensePlate"
                                            onBlur={() => plate.setWasTouched(true)}
                                            onChange={plateOnChange}
                                            placeholder="License Plate"
                                            required
                                            slotProps={{
                                                htmlInput: { maxLength: 10 },
                                                inputLabel: { required: false },
                                            }}
                                            value={plate.value}
                                        />
                                    </FormControl>
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, md: 3 }}>
                                        <FormControl fullWidth>
                                            <TextField
                                                disabled={readOnly}
                                                error={!!(state.wasTouched && !state?.value) || plateDecodeError}
                                                helperText={
                                                    ((state.wasTouched && !state?.value) || plateDecodeError) &&
                                                    (plateDecodeError ? 'State' : 'Please make a selection above')
                                                }
                                                id={STATE_SELECT_ID}
                                                label="State"
                                                name="state"
                                                onBlur={() => state.setWasTouched(true)}
                                                onChange={plateStateOnChange}
                                                required
                                                select
                                                slotProps={{
                                                    select: { native: true },
                                                    inputLabel: { required: false },
                                                }}
                                                value={state.value}
                                            >
                                                <option disabled hidden value=""></option>
                                                {usaStates.map(state => (
                                                    <option key={state.value} value={state.value}>
                                                        {state.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                        {zipField}
                                    </Stack>
                                </Stack>
                            </Box>
                        )}
                        {activeTab === tabState.VIN && (
                            <Box>
                                <Stack spacing={{ xs: 2, md: 3 }}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete="off"
                                            disabled={readOnly}
                                            error={!!(vin.wasTouched && vinValidationError)}
                                            helperText={vin.wasTouched && vinValidationError}
                                            id="ico-vehicle-vin"
                                            label="VIN"
                                            name="vin"
                                            onBlur={() => vin.setWasTouched(true)}
                                            onChange={e => vin.setValue(formatVin(e.target.value))}
                                            placeholder="VIN"
                                            required
                                            slotProps={{
                                                htmlInput: { maxLength: 17 },
                                                inputLabel: { required: false },
                                            }}
                                            value={vin.value}
                                        />
                                    </FormControl>
                                    {zipField}
                                </Stack>
                            </Box>
                        )}

                        <Button
                            color="cta"
                            fullWidth
                            id="ico-getstarted-button"
                            type="submit"
                            endIcon={<ArrowForward />}
                        >
                            Get my offer
                        </Button>
                        <Typography variant="caption" color="text.secondary">
                            Most cars qualify, some we&apos;ll ask to see in person.
                        </Typography>
                    </Stack>
                </form>
            </Paper>
        </Box>
    );
};

export default LicenseOrVin;
